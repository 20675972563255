import { Component, Input, OnInit } from '@angular/core';
import { AppRoute } from '../../../../routes';

@Component({
  selector: 'app-sidebar-item-predefined',
  template: `<app-sidebar-item
    [link]="[route.path]"
    [icon]="route.icon"
    *ngIf="
      (route.roles | hasPermission | async) &&
      (route.requiredFeatures | hasFeature | async)
    "
  >
    <ng-container slot="title">
      {{ route.name }}
    </ng-container>
  </app-sidebar-item> `,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class SidebarItemPredefinedComponent implements OnInit {
  @Input() route!: AppRoute;

  constructor() {}

  ngOnInit(): void {}
}
