import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { UserRole } from '@prisma/client';
import { take, tap } from 'rxjs';
import { AuthState } from 'src/app/auth/auth.state';
import { HelpFormComponent } from 'src/app/core/components/help-form/help-form.component';

@Injectable({
  providedIn: 'root',
})
export class HelpServiceTsService {
  employeeRole$ = this.store.select(AuthState.role);
  employeeName$ = this.store.select(AuthState.fullName);
  employeeEmail$ = this.store.select(AuthState.userEmail);

  constructor(
    private readonly store: Store,
    private readonly matDialog: MatDialog
  ) {}

  openHelpDialog() {
    this.employeeRole$
      .pipe(
        take(1),
        tap((role) => {
          if (role === UserRole.ADMIN) {
            // @TODO: Implement admin help form
          } else {
            this.matDialog.open(HelpFormComponent, {
              disableClose: true,
            });
          }
        })
      )
      .subscribe();
  }
}
