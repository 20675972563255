import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import type { Company } from '@prisma/client';
import jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { Employee } from '../admin/interface/employee.interface';
import { CompanyState } from '../core/state/company.state';
import { SetPalette } from '../core/state/palette.state';
import { mapCreatedUpdated } from '../interfaces/base.interface';
import { DownloadService } from '../shared/services/download.service';
import { delayRequest } from '../shared/util/delayed-request.util';
import { AccessToken } from './interfaces/access-token.interface';
import { RefreshToken } from './interfaces/refresh-token.interface';
import { TokenResponse } from './interfaces/token-response.interface';

export class GenerateOtp {
  static readonly type = '[Auth] Generate OTP';
  constructor(public payload: { email: string; password: string }) {}
}

export class LogIn {
  static readonly type = '[Auth] Log in';
  constructor(
    public payload: { email: string; password: string; otpCode: string }
  ) {}
}

export class LoadAssociatedEmployees {
  static readonly type = '[Auth] Load Associated Employees';
}

export class AssumeEmployee {
  static readonly type = '[Auth] Assume Employee';
  constructor(
    public payload: {
      companyId: string;
      employeeId: string;
      navigationUrl?: string;
    }
  ) {}
}

export class EpiUseSupportAssumeEmployee {
  static readonly type = '[Auth] EPI-USE Support Assume Employee';
  constructor(public token: string, public password: string) {}
}

export class RequestEpiUseUserSupport {
  static readonly type = '[Auth] Request EPI-USE User Support';
  constructor(public payload: { employeeNumber: string }) {}
}

export class LogOut {
  static readonly type = '[Auth] LogOut';
}

export class InviteUser {
  static readonly type = '[Auth] Invite User';
  constructor(
    public payload: { destinationEmail: string; employeeId: string }
  ) {}
}

export class Register {
  static readonly type = '[Auth] Register';
  constructor(
    public payload: {
      contactName: string;
      password: string;
      inviteToken: string;
    }
  ) {}
}

export class AcceptInvite {
  static readonly type = '[Auth] Accept Invite';
  constructor(
    public payload: {
      inviteToken: string;
    }
  ) {}
}

export class CheckIfUserAlreadyExistsForInvite {
  static readonly type = '[Auth] Check If User Already Exists For Invite';
  constructor(
    public payload: {
      inviteToken: string;
    }
  ) {}
}

export class ForgotPassword {
  static readonly type = '[Auth] Forgot Password';
  constructor(public payload: { email: string | null }) {}
}

export class ResetPassword {
  static readonly type = '[Auth] Reset Password';
  constructor(public payload: { password: string; token: string }) {}
}

export class ChangePassword {
  static readonly type = '[Auth] Update Password';
  constructor(
    public payload: { currentPassword: string; newPassword: string }
  ) {}
}

export class RefreshAuthTokens {
  static readonly type = '[Auth] Refresh Token';
}

export class LoadUserDetails {
  static readonly type = '[Auth] Load User Details';
}

export class ChangeEmail {
  static readonly type = '[Auth] Change Email';
  constructor(public payload: { newEmail: string; password: string }) {}
}

export class ConfirmEmailChange {
  static readonly type = '[Auth] Confirm Email Change';
  constructor(public payload: { token: string }) {}
}

export class RequestAllUserInformation {
  static readonly type = '[Auth] Request All User Information';
}

export class RequestAccountDeletion {
  static readonly type = '[Auth] Request Account Deletion';
  constructor(public payload: { password: string }) {}
}

export class ExchangeEncryptedTokenForJwt {
  static readonly type = '[Auth] Exchange Encrypted Token For Jwt';
  constructor(public payload: { token: string }) {}
}

export class LoadPublicCompanyDetails {
  static readonly type = '[Auth] Load Public Company Details';
}

export class ImpersonateEmployee {
  static readonly type = '[Auth] Impersonate Employee';
  constructor(
    public payload: { employeeNumber: string; isSwitchingBack?: boolean }
  ) {}
}

export type AssumableEmployee = Employee & {
  company: Pick<Company, 'id' | 'name' | 'companyLogoType'>;
};

export type PublicCompanyDetails = {
  id: string;
  name: string;
  contactNumber?: string;
  email?: string;
  website?: string;
  palette?: string;
  samlName?: string;
  entrypoint?: string;
  samlLogo?: string;
  isBwl: boolean;
};

export interface AuthStateModel {
  accessToken: string | null;
  refreshToken: string | null;
  generatingOtp?: boolean;
  loggingIn?: boolean;
  sendingResetLink?: boolean;
  resettingPassword?: boolean;
  updatingPassword?: boolean;
  registering?: boolean;
  userDetails?: Employee | null;
  loadingUserDetails: boolean;
  loadedUserDetails: boolean;
  userDetailsError: unknown | null;
  associatedEmployees: AssumableEmployee[];
  loadedAssociatedEmployees: boolean;
  loadingAssociatedEmployees: boolean;
  switchingAccount: boolean;
  publicCompanyDetails?: PublicCompanyDetails;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    accessToken: null,
    refreshToken: null,
    userDetails: null,
    loadingUserDetails: false,
    loadedUserDetails: false,
    userDetailsError: null,
    loadedAssociatedEmployees: false,
    loadingAssociatedEmployees: false,
    associatedEmployees: [],
    switchingAccount: false,
    updatingPassword: false,
  },
})
@Injectable()
export class AuthState {
  @Selector()
  static associatedEmployees(state: AuthStateModel) {
    return state.associatedEmployees;
  }

  @Selector()
  static loadingAssociatedEmployees(state: AuthStateModel) {
    return state.loadingAssociatedEmployees;
  }

  @Selector()
  static loadedAssociatedEmployees(state: AuthStateModel) {
    return state.loadedAssociatedEmployees;
  }

  @Selector()
  static accessToken(state: AuthStateModel) {
    if (!state.accessToken) return null;
    return jwtDecode(state.accessToken) as AccessToken;
  }

  @Selector()
  static accessTokenRaw(state: AuthStateModel) {
    return state.accessToken;
  }

  @Selector()
  static refreshToken(state: AuthStateModel) {
    return state.refreshToken && jwtDecode(state.refreshToken);
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel) {
    if (!state.refreshToken) return false;
    const { exp } = jwtDecode(state.refreshToken) as RefreshToken;
    return exp > Date.now() / 1000;
  }

  @Selector([AuthState.accessToken])
  static isImpersonating(state: AuthStateModel, accessToken: AccessToken) {
    return !!accessToken?.originalEmployeeNumber;
  }

  @Selector()
  static isSwitchingAccount(state: AuthStateModel) {
    return state.switchingAccount;
  }

  @Selector()
  static accessTokenHasExpired(state: AuthStateModel) {
    if (!state.accessToken) return true;
    const { exp } = jwtDecode(state.accessToken) as AccessToken;
    const BUFFER = 10_000; // give a few seconds grace for token refresh
    return exp - BUFFER < Date.now() / 1000;
  }

  @Selector()
  static refreshTokenExpires(state: AuthStateModel) {
    if (!state.refreshToken) return null;
    const { exp } = jwtDecode(state.refreshToken) as RefreshToken;
    return new Date(exp * 1000);
  }

  @Selector()
  static refreshTokenHasExpired(state: AuthStateModel) {
    if (!state.refreshToken) return true;
    const { exp } = jwtDecode(state.refreshToken) as RefreshToken;
    return exp < Date.now() / 1000;
  }

  @Selector([AuthState.accessToken])
  static role(state: AuthStateModel, accessToken: AccessToken) {
    return accessToken?.role;
  }

  @Selector([AuthState.accessToken])
  static features(state: AuthStateModel, accessToken: AccessToken) {
    return accessToken?.features;
  }

  @Selector([AuthState.accessToken])
  static companyId(state: AuthStateModel, accessToken: AccessToken) {
    return accessToken?.companyId;
  }

  @Selector()
  static generatingOtp(state: AuthStateModel) {
    return !!state.generatingOtp;
  }

  @Selector()
  static loggingIn(state: AuthStateModel) {
    return !!state.loggingIn;
  }

  @Selector()
  static sendingResetLink(state: AuthStateModel) {
    return !!state.sendingResetLink;
  }

  @Selector()
  static resettingPassword(state: AuthStateModel) {
    return !!state.resettingPassword;
  }

  @Selector()
  static registering(state: AuthStateModel) {
    return !!state.registering;
  }

  @Selector()
  static userDetails(state: AuthStateModel) {
    return state.userDetails;
  }

  @Selector()
  static loadingUserDetails(state: AuthStateModel) {
    return state.loadingUserDetails;
  }

  @Selector()
  static loadedUserDetails(state: AuthStateModel) {
    return state.loadedUserDetails;
  }

  @Selector()
  static userDetailsError(state: AuthStateModel) {
    return state.userDetailsError;
  }

  @Selector([AuthState.userDetails])
  static fullName(state: AuthStateModel, userDetails: Employee) {
    if (!userDetails) return null;
    return `${userDetails.firstName} ${userDetails.lastName}`;
  }

  @Selector([AuthState.userDetails])
  static userEmail(state: AuthStateModel, userDetails: Employee) {
    if (!userDetails) return null;
    return userDetails.user?.email;
  }

  @Selector([AuthState.userDetails])
  static employeeNumber(state: AuthStateModel, userDetails: Employee) {
    return userDetails?.employeeNumber;
  }

  @Selector([AuthState.accessToken])
  static employeeId(token: AccessToken) {
    return token.sub;
  }

  @Selector([CompanyState.company])
  static currency(state: AuthStateModel, company: Company) {
    return state.userDetails?.currency ?? company?.currency ?? 'USD';
  }

  @Selector([CompanyState.company])
  static payPeriod(state: AuthStateModel, company: Company) {
    if (
      state.userDetails?.payPeriodUnit &&
      state.userDetails?.payPeriodStartDate
    ) {
      return {
        payPeriodUnit: state.userDetails.payPeriodUnit,
        payPeriodStartDate: new Date(state.userDetails.payPeriodStartDate),
      };
    } else {
      return {
        payPeriodUnit: company.payPeriodUnit,
        payPeriodStartDate: new Date(company.payPeriodStartDate),
      };
    }
  }

  @Selector()
  static publicCompanyDetails(state: AuthStateModel) {
    return state.publicCompanyDetails;
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
    private downloader: DownloadService
  ) {}

  @Action(GenerateOtp)
  async generateOtp(
    ctx: StateContext<AuthStateModel>,
    { payload }: GenerateOtp
  ) {
    const { email, password } = payload;

    ctx.patchState({
      generatingOtp: true,
    });

    try {
      await lastValueFrom(
        this.http.post(`${environment.apiUrl}/v1/auth/login`, {
          email,
          password,
        })
      );

      ctx.patchState({
        generatingOtp: false,
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        generatingOtp: false,
      });
    }
  }

  /**
   * Validate user credentials with OTP code
   * Once validated, returns temporary JWT credentials for the user
   * to select a tenant.
   */
  @Action(LogIn)
  async logIn(ctx: StateContext<AuthStateModel>, { payload }: LogIn) {
    const { email, password, otpCode } = payload;

    ctx.patchState({
      loggingIn: true,
    });

    try {
      const res = await lastValueFrom(
        this.http.post<TokenResponse>(`${environment.apiUrl}/v1/auth/login`, {
          email,
          password,
          otpCode,
        })
      );

      const { accessToken, refreshToken } = res;

      jwtDecode(accessToken) as AccessToken;
      jwtDecode(refreshToken) as RefreshToken;

      ctx.patchState({
        accessToken: accessToken,
        refreshToken: refreshToken,
      });

      return ctx.dispatch(new LoadAssociatedEmployees());
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loggingIn: false,
      });
    }
  }

  @Action(AssumeEmployee)
  async assumeEmployee(
    ctx: StateContext<AuthStateModel>,
    { payload }: AssumeEmployee
  ) {
    ctx.patchState({
      switchingAccount: true,
    });

    try {
      const res = await lastValueFrom(
        this.http.post<TokenResponse>(
          `${environment.apiUrl}/v1/auth/assume-employee`,
          {
            employeeId: payload.employeeId,
            companyId: payload.companyId,
          }
        )
      );

      await this.switchEmployee(res, ctx, payload.navigationUrl);
    } catch (err) {
      ctx.patchState({
        switchingAccount: false,
      });
      throw err;
    }
  }

  private async switchEmployee(
    res: TokenResponse,
    ctx: StateContext<AuthStateModel>,
    navigationUrl: string | null = null
  ) {
    const { accessToken, refreshToken } = res;

    jwtDecode(accessToken) as AccessToken;
    jwtDecode(refreshToken) as RefreshToken;

    ctx.patchState({
      accessToken: accessToken,
      refreshToken: refreshToken,
    });

    // reload the page and navigate to the redirect URL or to home
    if (!navigationUrl) {
      window.location.href = '/';
    } else {
      const url = new URL(navigationUrl, window.location.href);
      url.host = window.location.host;
      window.location.href = url.href;
    }
  }

  @Action(ForgotPassword)
  async forgotPassword(
    ctx: StateContext<AuthStateModel>,
    { payload }: ForgotPassword
  ) {
    const { email } = payload;

    ctx.patchState({
      sendingResetLink: true,
    });

    try {
      const res = await delayRequest(
        lastValueFrom(
          this.http.post(`${environment.apiUrl}/v1/auth/forgot`, {
            email,
          })
        )
      );
    } catch (err: any) {
      throw err;
    } finally {
      ctx.patchState({
        sendingResetLink: false,
      });
    }
  }

  @Action(ResetPassword)
  async resetPassword(
    ctx: StateContext<AuthStateModel>,
    { payload }: ResetPassword
  ) {
    const { token, password } = payload;

    ctx.patchState({
      resettingPassword: true,
    });

    try {
      const res = await lastValueFrom(
        this.http.post(`${environment.apiUrl}/v1/auth/reset-password`, {
          token,
          password,
        })
      );
    } catch (err: any) {
      throw err;
    } finally {
      ctx.patchState({
        resettingPassword: false,
      });
    }
  }

  /**
   * Will be used from the profile settings page to update the user's password.
   */
  @Action(ChangePassword)
  async changePassword(
    ctx: StateContext<AuthStateModel>,
    { payload }: ChangePassword
  ) {
    const { currentPassword, newPassword } = payload;

    ctx.patchState({
      updatingPassword: true,
    });

    try {
      const res = await lastValueFrom(
        this.http.post(`${environment.apiUrl}/v1/auth/change-password`, {
          currentPassword,
          newPassword,
        })
      );

      return ctx.dispatch(new LogOut());
    } catch (err: any) {
      throw err;
    } finally {
      ctx.patchState({
        updatingPassword: false,
      });
    }
  }

  @Action(Register)
  async register(ctx: StateContext<AuthStateModel>, { payload }: Register) {
    const { inviteToken, password, contactName } = payload;

    ctx.patchState({
      registering: true,
    });

    try {
      const res = await lastValueFrom(
        this.http.post(`${environment.apiUrl}/v1/auth/register`, {
          inviteCode: inviteToken,
          password,
          contactName,
        })
      );
    } catch (err: any) {
      throw err;
    } finally {
      ctx.patchState({
        registering: false,
      });
    }
  }

  @Action(AcceptInvite)
  async acceptInvite(
    ctx: StateContext<AuthStateModel>,
    { payload }: AcceptInvite
  ) {
    const { inviteToken } = payload;

    return this.http.post(`${environment.apiUrl}/v1/auth/accept-invite`, {
      inviteCode: inviteToken,
    });
  }

  @Action(CheckIfUserAlreadyExistsForInvite)
  async checkIfUserAlreadyExistsForInvite(
    ctx: StateContext<AuthStateModel>,
    { payload }: AcceptInvite
  ) {
    const { inviteToken } = payload;

    return this.http.post(
      `${environment.apiUrl}/v1/auth/check-if-user-already-exists-for-invite`,
      {
        inviteCode: inviteToken,
      }
    );
  }

  @Action(RefreshAuthTokens)
  async refreshAuthTokens(ctx: StateContext<AuthStateModel>) {
    const { refreshToken } = ctx.getState();

    if (this.store.selectSnapshot(AuthState.refreshTokenHasExpired)) {
      // refresh token has expired, log the user out
      ctx.dispatch(new LogOut());
      return;
    }

    let res: TokenResponse;
    try {
      res = await lastValueFrom(
        this.http.post<TokenResponse>(`${environment.apiUrl}/v1/auth/refresh`, {
          refreshToken,
        })
      );
    } catch (err) {
      console.error(err);
      if (err instanceof HttpErrorResponse && err.status === 401) {
        // could not refresh the token, log the user out
        ctx.dispatch(new LogOut());
        return;
      }
      throw err;
    }

    const { accessToken, refreshToken: newRefreshToken } = res;
    jwtDecode(accessToken) as AccessToken;
    jwtDecode(newRefreshToken) as RefreshToken;

    ctx.patchState({
      accessToken: accessToken,
      refreshToken: newRefreshToken,
    });
  }

  @Action(LogOut)
  async logOut(ctx: StateContext<AuthStateModel>) {
    try {
      await lastValueFrom(
        this.http.post<LogOut>(`${environment.apiUrl}/v1/auth/logout`, {})
      );
    } catch (err) {
      console.error(err);
    }

    // clear tokens from store and from localStorage
    ctx.patchState({
      accessToken: null,
      refreshToken: null,
    });

    this.router.navigate(['/auth/logout']);

    setTimeout(() => {
      window.location.href = '/';
    }, 0);
  }

  @Action(InviteUser)
  async inviteUser(ctx: StateContext<AuthStateModel>, { payload }: InviteUser) {
    return await delayRequest(
      lastValueFrom(
        this.http.post(`${environment.apiUrl}/v1/auth/invite`, {
          destinationEmail: payload.destinationEmail,
          employeeId: payload.employeeId,
        })
      )
    );
  }

  @Action(LoadUserDetails)
  async loadUserDetails(ctx: StateContext<AuthStateModel>) {
    try {
      ctx.patchState({
        loadingUserDetails: true,
        userDetailsError: null,
      });

      const res = await lastValueFrom(
        this.http.get<Employee>(`${environment.apiUrl}/v1/auth/me`)
      );

      // get the user's currency
      if (res.currency) {
      }

      ctx.patchState({
        userDetails: {
          ...res,
          ...mapCreatedUpdated(res),
          payPeriodStartDate: res.payPeriodStartDate
            ? new Date(res.payPeriodStartDate)
            : null,
        },
      });
    } catch (err) {
      ctx.patchState({
        userDetailsError: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loadingUserDetails: false,
        loadedUserDetails: true,
      });
    }
  }

  /**
   * After the user has clicked the email change confirmation link from their
   * inbox, submit the token to the backend to confirm the change. If successful,
   * the backend will return a new access token and refresh token.
   */
  @Action(ConfirmEmailChange)
  async confirmEmailChange(
    ctx: StateContext<AuthStateModel>,
    { payload }: ConfirmEmailChange
  ) {
    const token = payload.token;

    try {
      const res = await lastValueFrom(
        this.http.post<any>(
          `${environment.apiUrl}/v1/auth/confirm-email-change`,
          {
            token,
          }
        )
      );

      const { accessToken, refreshToken: newRefreshToken } = res;
      jwtDecode(accessToken) as AccessToken;
      jwtDecode(newRefreshToken) as RefreshToken;

      ctx.patchState({
        accessToken: accessToken,
        refreshToken: newRefreshToken,
      });
    } catch (err) {
      throw err;
    } finally {
    }
  }

  @Action(ChangeEmail)
  async changeEmail(
    ctx: StateContext<AuthStateModel>,
    { payload }: ChangeEmail
  ) {
    const { newEmail, password } = payload;

    try {
      await delayRequest(
        lastValueFrom(
          this.http.post(`${environment.apiUrl}/v1/auth/change-email`, {
            newEmail,
            password,
          })
        )
      );
    } catch (err) {
      throw err;
    } finally {
    }
  }

  @Action(RequestAllUserInformation)
  async requestAllUserInformation(ctx: StateContext<AuthStateModel>) {
    try {
      // download employee data flat file
      const data = await delayRequest(
        lastValueFrom(
          this.http.get(`${environment.apiUrl}/v1/auth/data`, {
            responseType: 'blob',
          })
        ),
        2000
      );

      this.downloader.downloadBlob(data, '360-my-data.txt');

      // download documents zip
      const documentsData = await delayRequest(
        lastValueFrom(
          this.http.get(`${environment.apiUrl}/v1/auth/documents`, {
            responseType: 'blob',
          })
        ),
        2000
      );

      this.downloader.downloadBlob(documentsData, '360-my-documents.zip');
    } catch (err) {
      throw err;
    } finally {
    }
  }

  @Action(LoadAssociatedEmployees)
  async loadAssociatedEmployees(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      loadingAssociatedEmployees: true,
    });
    try {
      const res = await lastValueFrom(
        this.http.get<AssumableEmployee[]>(
          `${environment.apiUrl}/v1/auth/associated-employees`
        )
      );

      ctx.patchState({
        associatedEmployees: res,
        loadedAssociatedEmployees: true,
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loadingAssociatedEmployees: false,
      });
    }
  }

  @Action(ExchangeEncryptedTokenForJwt)
  async exchangeEncryptedTokenForJwt(
    ctx: StateContext<AuthStateModel>,
    { payload }: ExchangeEncryptedTokenForJwt
  ) {
    const res = await lastValueFrom(
      this.http.post<TokenResponse>(
        `${environment.apiUrl}/v1/auth/exchange-encrypted-token`,
        {
          token: payload.token,
        }
      )
    );

    const { accessToken, refreshToken } = res;

    jwtDecode(accessToken) as AccessToken;
    jwtDecode(refreshToken) as RefreshToken;

    ctx.patchState({
      accessToken: accessToken,
      refreshToken: refreshToken,
    });

    window.location.href = '/';
  }

  @Action(RequestAccountDeletion)
  async requestAccountDeletion(
    ctx: StateContext<AuthStateModel>,
    { payload }: RequestAccountDeletion
  ) {
    try {
      await delayRequest(
        lastValueFrom(
          this.http.post(`${environment.apiUrl}/v1/auth/request-deletion`, {
            password: payload.password,
          })
        )
      );

      return ctx.dispatch(new LogOut());
    } catch (err) {
      throw err;
    } finally {
    }
  }

  @Action(LoadPublicCompanyDetails)
  async loadPublicCompanyDetails(ctx: StateContext<AuthStateModel>) {
    try {
      const details = await lastValueFrom(
        this.http.get<PublicCompanyDetails | null>(
          `${environment.apiUrl}/v1/company/public-details`
        )
      );

      if (!details) {
        return;
      }

      ctx.patchState({
        publicCompanyDetails: details,
      });

      if (details.palette) {
        ctx.dispatch(new SetPalette(details.palette));
      }
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === 404) {
        this.router.navigate(['/not-found']);
      }
      console.error(err);
    }
  }

  @Action(ImpersonateEmployee)
  async impersonateEmployee(
    ctx: StateContext<AuthStateModel>,
    action: ImpersonateEmployee
  ) {
    try {
      ctx.patchState({
        switchingAccount: true,
      });

      const res = await lastValueFrom(
        this.http.post<TokenResponse>(
          `${environment.apiUrl}/v1/auth/impersonate-employee/${action.payload.employeeNumber}`,
          {}
        )
      );

      await this.switchEmployee(
        res,
        ctx,
        action.payload.isSwitchingBack ? '/admin/employees' : null
      );
    } catch (err) {
      ctx.patchState({
        switchingAccount: false,
      });
      throw err;
    }
  }

  @Action(EpiUseSupportAssumeEmployee)
  async epiUseSupportAssumeEmployee(
    ctx: StateContext<AuthStateModel>,
    action: EpiUseSupportAssumeEmployee
  ) {
    try {
      ctx.patchState({
        switchingAccount: true,
      });

      const res = await lastValueFrom(
        this.http.post<TokenResponse>(
          `${environment.apiUrl}/v1/auth/epi-use-support-assume-employee`,
          {
            token: action.token,
          },
          {
            headers: {
              'x-support-key': action.password,
            },
          }
        )
      );

      await this.switchEmployee(res, ctx);
    } catch (err) {
      ctx.patchState({
        switchingAccount: false,
      });
      throw err;
    }
  }

  @Action(RequestEpiUseUserSupport)
  async requestEpiUseUserSupport(
    ctx: StateContext<AuthStateModel>,
    action: RequestEpiUseUserSupport
  ) {
    try {
      const res = await lastValueFrom(
        this.http.post<TokenResponse>(
          `${environment.apiUrl}/v1/auth/request-epi-use-support-assume-employee`,
          {
            employeeNumber: action.payload.employeeNumber,
            expires: DateTime.now().plus({ weeks: 2 }).toJSDate(),
          }
        )
      );
    } catch (err) {
      throw err;
    }
  }
}
