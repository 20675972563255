type PageNumberPagination = {
  isFirstPage: boolean;
  isLastPage: boolean;
  currentPage: number;
  previousPage: number | null;
  nextPage: number | null;
  pageCount: number;
  totalCount: number;
};

export type PaginationResponse<T> = {
  data: T;
  meta?: PageNumberPagination;
};

export type PaginationQuery = {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  sortColumn?: string;
  sortDirection?: string;
};

export function sanitizePaginationQuery(
  query: PaginationQuery
): PaginationQuery {
  return {
    ...query,
    page: query.page || 1,
    searchTerm: query.searchTerm?.trim() || '',
    sortColumn: query.sortColumn?.trim() || '',
    sortDirection: query.sortDirection?.trim() || '',
  };
}
