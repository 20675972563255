import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-separator',
  template: `<div class="mt-4 mb-4 h-[1px] bg-gray-700"></div> `,
})
export class SidebarSeparatorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
