import { CompanyFeature } from './auth/interfaces/company-feature.enum';
import { UserRole } from './auth/interfaces/user-role.enum';

/**
 * Central definition for different routes. Used to avoid magic strings and
 * to make it easier to change routes in the future.
 */

export interface AppRoute {
  name: string;
  description?: string;
  path: string;
  icon: string;
  roles?: UserRole[];
  requiredFeatures?: CompanyFeature[];
}

export const WELCOME: AppRoute = {
  name: 'Welcome',
  icon: 'menu_book',
  path: '/welcome',
  roles: [UserRole.USER, UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.WELCOME],
};

export const OVERVIEW: AppRoute = {
  name: 'Overview',
  icon: 'dashboard',
  path: '/overview',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.OVERVIEW],
};

export const BENEFITS: AppRoute = {
  name: 'Benefits',
  icon: 'handshake',
  path: '/benefits/benefits',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.BENEFITS],
};

export const WORK_LIFE_BENEFITS: AppRoute = {
  name: 'Work-Life Benefits',
  icon: 'volunteer_activism',
  path: '/benefits/work-life-benefits',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.WORK_LIFE_BENEFITS],
};

export const COMPENSATION: AppRoute = {
  name: 'Compensation',
  icon: 'account_balance',
  path: '/compensation',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.COMPENSATION],
};

export const PAID_TIME_OFF: AppRoute = {
  name: 'Paid Time Off',
  icon: 'schedule',
  path: '/paid-time-off',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.PAID_TIME_OFF],
};

export const CONTACT_INFORMATION: AppRoute = {
  name: 'Contact Information',
  icon: 'alternate_email',
  path: '/contact-information',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.CONTACTS],
};

export const STATEMENT: AppRoute = {
  name: '360º Statement',
  icon: 'fact_check',
  path: '/statement',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.STATEMENT],
};

export const NEWS: AppRoute = {
  name: '360º News',
  icon: 'history_edu',
  path: '/news',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.NEWS],
};

export const PAYSLIPS: AppRoute = {
  name: 'Payslips',
  icon: 'receipt_long',
  path: '/payslips',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.PAYSLIPS],
};

export const TAX_FORMS: AppRoute = {
  name: 'Tax Forms',
  icon: 'assignment',
  path: '/tax-forms',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.TAX_FORMS],
};

export const USER_MANAGEMENT: AppRoute = {
  name: 'Manage Users',
  icon: 'people',
  path: '/admin/employees',
  roles: [UserRole.ADMIN],
};

export const COMPENSATION_SETTINGS: AppRoute = {
  name: COMPENSATION.name,
  icon: COMPENSATION.icon,
  path: '/admin/compensation',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.COMPENSATION],
};

export const PAID_TIME_OFF_SETTINGS: AppRoute = {
  name: PAID_TIME_OFF.name,
  icon: PAID_TIME_OFF.icon,
  path: '/admin/paid-time-off',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.PAID_TIME_OFF],
};

export const BENEFITS_SETTINGS: AppRoute = {
  name: BENEFITS.name,
  icon: BENEFITS.icon,
  path: '/admin/benefits',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.BENEFITS],
};

export const WORK_LIFE_BENEFITS_SETTINGS: AppRoute = {
  name: WORK_LIFE_BENEFITS.name,
  icon: WORK_LIFE_BENEFITS.icon,
  path: '/admin/work-life-benefits',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.WORK_LIFE_BENEFITS],
};

export const OVERVIEW_SETTINGS: AppRoute = {
  name: OVERVIEW.name,
  icon: OVERVIEW.icon,
  path: '/admin/overview',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.OVERVIEW],
};

export const CONTACT_INFORMATION_SETTINGS: AppRoute = {
  name: CONTACT_INFORMATION.name,
  icon: CONTACT_INFORMATION.icon,
  path: '/admin/contact-information',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.CONTACTS],
};

export const STATEMENT_SETTINGS: AppRoute = {
  name: STATEMENT.name,
  icon: STATEMENT.icon,
  path: '/admin/statement',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.STATEMENT],
};

export const COMPANY_SETTINGS: AppRoute = {
  name: 'Company',
  icon: 'business',
  path: '/admin/company',
  roles: [UserRole.ADMIN],
};

export const NEWS_SETTINGS: AppRoute = {
  name: NEWS.name,
  icon: NEWS.icon,
  path: '/admin/news',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.NEWS],
};

export const WELCOME_SETTINGS: AppRoute = {
  name: WELCOME.name,
  icon: WELCOME.icon,
  path: '/admin/welcome',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.WELCOME],
};

export const PROCESSING_LOGS: AppRoute = {
  name: 'Processing Logs',
  icon: 'view_timeline',
  path: '/admin/processing-logs',
  roles: [UserRole.ADMIN],
};

export const USER_LOGS: AppRoute = {
  name: 'Access Logs',
  icon: 'history',
  path: '/admin/access-logs',
  roles: [UserRole.ADMIN],
};

export const MANAGE_MY_INFORMATION: AppRoute = {
  name: 'Manage My Information',
  icon: 'account_circle',
  path: '/manage-my-information',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.MANAGE_MY_INFORMATION],
};

export const MANAGE_MY_INFORMATION_SETTINGS: AppRoute = {
  name: MANAGE_MY_INFORMATION.name,
  icon: MANAGE_MY_INFORMATION.icon,
  path: '/admin/manage-my-information',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.MANAGE_MY_INFORMATION],
};

export const CHANGE_APPROVAL: AppRoute = {
  name: 'Change Approval',
  icon: 'inventory',
  path: '/admin/change-approval',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.MANAGE_MY_INFORMATION],
};

export const MANAGE_MY_PERSONAL_INFORMATION: AppRoute = {
  name: 'Personal Information',
  description: 'Contact information and marital status',
  icon: 'contact_page',
  path: '/manage-my-information/personal-information',
  roles: [UserRole.USER, UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.PERSONAL_INFORMATION],
};

export const MANAGE_MY_ADDRESS: AppRoute = {
  name: 'Address',
  description: 'Your current residential address',
  icon: 'home',
  path: '/manage-my-information/address',
  roles: [UserRole.USER, UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.ADDRESS],
};

export const MANAGE_MY_DEPENDENTS: AppRoute = {
  name: 'Dependents',
  description: 'People who depend on you financially',
  icon: 'groups_2',
  path: '/manage-my-information/dependents',
  roles: [UserRole.USER, UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.DEPENDENTS],
};

export const MANAGE_MY_PAYMENT_INFORMATION: AppRoute = {
  name: 'Payment Information',
  description: 'How you receive payments from the company',
  icon: 'payments',
  path: '/manage-my-information/payment-information',
  roles: [UserRole.USER, UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.PAYMENT_INFORMATION],
};

export const MANAGE_MY_EMERGENCY_CONTACTS: AppRoute = {
  name: 'Emergency Contacts',
  description: 'Who we can get in touch with in an emergency',
  icon: 'contact_emergency',
  path: '/manage-my-information/emergency-contacts',
  roles: [UserRole.USER, UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.EMERGENCY_CONTACTS],
};

export const MANAGE_MY_INFORMATION_ROUTES = [
  MANAGE_MY_PERSONAL_INFORMATION,
  MANAGE_MY_ADDRESS,
  MANAGE_MY_PAYMENT_INFORMATION,
  MANAGE_MY_DEPENDENTS,
  MANAGE_MY_EMERGENCY_CONTACTS,
] as const;

export const REPORTING: AppRoute = {
  name: 'Reporting',
  icon: 'analytics',
  path: '/reporting',
  roles: [UserRole.ADMIN],
};

export const REPORTING_BENEFITS: AppRoute = {
  name: 'Benefits',
  description: 'Summary of Benefit data',
  icon: BENEFITS.icon,
  path: '/reporting/benefits',
  roles: [UserRole.ADMIN],
  requiredFeatures: BENEFITS.requiredFeatures,
};

export const REPORTING_COMPENSATION: AppRoute = {
  name: 'Compensation',
  description: 'Summary of Compensation data',
  icon: COMPENSATION.icon,
  path: '/reporting/compensation',
  roles: [UserRole.ADMIN],
  requiredFeatures: COMPENSATION.requiredFeatures,
};

export const REPORTING_PAID_TIME_OFF: AppRoute = {
  name: 'Paid Time Off',
  description: 'Summary of Paid Time Off data',
  icon: PAID_TIME_OFF.icon,
  path: '/reporting/time',
  roles: [UserRole.ADMIN],
  requiredFeatures: PAID_TIME_OFF.requiredFeatures,
};

export const REPORTING_PERSONAL_INFORMATION: AppRoute = {
  name: 'Personal Information',
  description: 'Summary of Personal Information data',
  icon: MANAGE_MY_PERSONAL_INFORMATION.icon,
  path: '/reporting/personal-information',
  roles: [UserRole.ADMIN],
  requiredFeatures: MANAGE_MY_PERSONAL_INFORMATION.requiredFeatures,
};

export const REPORTING_PAYMENT_INFORMATION: AppRoute = {
  name: 'Payment Information',
  description: 'Summary of Payment Information data',
  icon: MANAGE_MY_PAYMENT_INFORMATION.icon,
  path: '/reporting/payment-information',
  roles: [UserRole.ADMIN],
  requiredFeatures: MANAGE_MY_PAYMENT_INFORMATION.requiredFeatures,
};

export const REPORTING_DEPENDENTS: AppRoute = {
  name: 'Dependents',
  description: 'Summary of Dependent data',
  icon: MANAGE_MY_DEPENDENTS.icon,
  path: '/reporting/dependents',
  roles: [UserRole.ADMIN],
  requiredFeatures: MANAGE_MY_DEPENDENTS.requiredFeatures,
};

export const REPORTING_ADDRESS: AppRoute = {
  name: 'Address',
  description: 'Summary of Address data',
  icon: MANAGE_MY_ADDRESS.icon,
  path: '/reporting/address',
  roles: [UserRole.ADMIN],
  requiredFeatures: MANAGE_MY_ADDRESS.requiredFeatures,
};

export const REPORTING_EMERGENCY_CONTACTS: AppRoute = {
  name: 'Emergency Contacts',
  description: 'Summary of Emergency Contact data',
  icon: MANAGE_MY_EMERGENCY_CONTACTS.icon,
  path: '/reporting/emergency-contact',
  roles: [UserRole.ADMIN],
  requiredFeatures: MANAGE_MY_EMERGENCY_CONTACTS.requiredFeatures,
};

export const REPORTING_EMPLOYEES: AppRoute = {
  name: 'Employees',
  description: 'Summary of Employee data',
  icon: USER_MANAGEMENT.icon,
  path: '/reporting/employees',
  roles: [UserRole.ADMIN],
  requiredFeatures: USER_MANAGEMENT.requiredFeatures,
};

export const REPORTING_ROUTES = [
  REPORTING_EMPLOYEES,
  REPORTING_BENEFITS,
  REPORTING_COMPENSATION,
  REPORTING_PAID_TIME_OFF,
  REPORTING_PERSONAL_INFORMATION,
  REPORTING_ADDRESS,
  REPORTING_PAYMENT_INFORMATION,
  REPORTING_DEPENDENTS,
  REPORTING_EMERGENCY_CONTACTS,
] as const;

export const APP_ROUTES = {
  WELCOME,
  OVERVIEW,
  BENEFITS,
  WORK_LIFE_BENEFITS,
  COMPENSATION,
  PAID_TIME_OFF,
  CONTACT_INFORMATION,
  STATEMENT,
  NEWS,
  PAYSLIPS,
  TAX_FORMS,

  USER_MANAGEMENT,
  COMPENSATION_SETTINGS,
  PAID_TIME_OFF_SETTINGS,
  BENEFITS_SETTINGS,
  WORK_LIFE_BENEFITS_SETTINGS,
  OVERVIEW_SETTINGS,
  CONTACT_INFORMATION_SETTINGS,
  WELCOME_SETTINGS,
  STATEMENT_SETTINGS,
  COMPANY_SETTINGS,
  NEWS_SETTINGS,

  PROCESSING_LOGS,
  USER_LOGS,

  MANAGE_MY_INFORMATION,
  MANAGE_MY_ADDRESS,
  MANAGE_MY_PERSONAL_INFORMATION,
  MANAGE_MY_EMERGENCY_CONTACTS,
  MANAGE_MY_PAYMENT_INFORMATION,
  MANAGE_MY_DEPENDENTS,
  MANAGE_MY_INFORMATION_SETTINGS,
  CHANGE_APPROVAL,

  REPORTING,
  REPORTING_ADDRESS,
  REPORTING_BENEFITS,
  REPORTING_COMPENSATION,
  REPORTING_DEPENDENTS,
  REPORTING_EMERGENCY_CONTACTS,
  REPORTING_PAID_TIME_OFF,
  REPORTING_PAYMENT_INFORMATION,
  REPORTING_PERSONAL_INFORMATION,
};
