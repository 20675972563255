import {
  APP_BASE_HREF,
  LocationStrategy,
  PathLocationStrategy,
  PlatformLocation,
} from '@angular/common';
import { Inject, Injectable, NgModule, Optional } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  RouterModule,
  RouterStateSnapshot,
  Routes,
  TitleStrategy,
  UrlSerializer,
} from '@angular/router';
import { Store } from '@ngxs/store';
import type { CompanyFeatureType } from '@prisma/client';
import { CompanyLogoType } from '@prisma/client';
import { CompanyResolverService } from './admin/services/company-resolver.service';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './auth/guards/auth.guard';
import { UserRole } from './auth/interfaces/user-role.enum';
import { PublicCompanyDetailsResolverService } from './auth/services/public-company-details-resolver.service';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { UserDetailsResolverService } from './core/services/user-details-resolver.service';
import { CompanyState } from './core/state/company.state';
import {
  BENEFITS,
  CHANGE_APPROVAL,
  COMPENSATION,
  CONTACT_INFORMATION,
  MANAGE_MY_INFORMATION,
  NEWS,
  OVERVIEW,
  PAID_TIME_OFF,
  PAYSLIPS,
  REPORTING,
  STATEMENT,
  TAX_FORMS,
  WELCOME,
  WORK_LIFE_BENEFITS,
} from './routes';
import { FeatureFlagRedirectGuard } from './shared/guards/feature-flag-redirect.guard';
import { FeatureFlagGuard } from './shared/guards/feature-flag.guard';

const routes: Routes = [
  // all primary routes require authentication
  {
    path: '',
    canActivateChild: [AuthGuard, FeatureFlagGuard],
    resolve: {
      userDetails: UserDetailsResolverService,
      company: CompanyResolverService,
    },
    children: [
      // the home route depends on the active feature flags
      {
        path: '',
        pathMatch: 'full',
        canActivate: [FeatureFlagRedirectGuard],
        component: NotFoundComponent,
      },

      {
        path: 'admin/change-approval',
        loadChildren: () =>
          import('./change-approval/change-approval.module').then(
            (m) => m.ChangeApprovalModule
          ),
        data: {
          roles: CHANGE_APPROVAL.roles,
          features: CHANGE_APPROVAL.requiredFeatures,
        },
      },

      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
        data: {
          roles: [UserRole.ADMIN],
        },
      },
      {
        path: 'overview',
        loadChildren: () =>
          import('./overview/overview.module').then((m) => m.OverviewModule),
        data: { roles: OVERVIEW.roles, features: OVERVIEW.requiredFeatures },
      },
      {
        path: 'welcome',
        loadChildren: () =>
          import('./welcome/welcome.module').then((m) => m.WelcomeModule),
        data: { roles: WELCOME.roles, features: WELCOME.requiredFeatures },
      },
      {
        path: 'benefits',
        loadChildren: () =>
          import('./benefits/benefits.module').then((m) => m.BenefitsModule),
        data: {
          roles: [
            ...(BENEFITS.roles as UserRole[]),
            ...(WORK_LIFE_BENEFITS.roles as UserRole[]),
          ],
          features: [
            ...(BENEFITS.requiredFeatures as CompanyFeatureType[]),
            ...(WORK_LIFE_BENEFITS.requiredFeatures as CompanyFeatureType[]),
          ],
        },
      },
      {
        path: 'compensation',
        loadChildren: () =>
          import('./compensation/compensation.module').then(
            (m) => m.CompensationModule
          ),
        data: {
          roles: COMPENSATION.roles,
          features: COMPENSATION.requiredFeatures,
        },
      },
      {
        path: 'paid-time-off',
        loadChildren: () =>
          import('./paid-time-off/paid-time-off.module').then(
            (m) => m.PaidTimeOffModule
          ),
        data: {
          roles: PAID_TIME_OFF.roles,
          features: PAID_TIME_OFF.requiredFeatures,
        },
      },
      {
        path: 'contact-information',
        loadChildren: () =>
          import('./contacts/contacts.module').then((m) => m.ContactsModule),
        data: {
          roles: CONTACT_INFORMATION.roles,
          features: CONTACT_INFORMATION.requiredFeatures,
        },
      },
      {
        path: 'statement',
        loadChildren: () =>
          import('./statement/statement.module').then((m) => m.StatementModule),
        data: { roles: STATEMENT.roles, features: STATEMENT.requiredFeatures },
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./news/news.module').then((m) => m.NewsModule),
        data: { roles: NEWS.roles, features: NEWS.requiredFeatures },
      },
      {
        path: 'payslips',
        loadChildren: () =>
          import('./payslip/payslip.module').then((m) => m.PayslipModule),
        data: { roles: PAYSLIPS.roles, features: PAYSLIPS.requiredFeatures },
      },
      {
        path: 'tax-forms',
        loadChildren: () =>
          import('./tax-forms/tax-forms.module').then((m) => m.TaxFormsModule),
        data: { roles: TAX_FORMS.roles, features: TAX_FORMS.requiredFeatures },
      },
      {
        path: 'manage-my-information',
        loadChildren: () =>
          import('./manage-my-information/manage-my-information.module').then(
            (m) => m.ManageMyInformationModule
          ),
        data: {
          roles: MANAGE_MY_INFORMATION.roles,
          features: MANAGE_MY_INFORMATION.requiredFeatures,
        },
        title: MANAGE_MY_INFORMATION.name,
      },
      {
        path: 'reporting',
        loadChildren: () =>
          import('./reporting/reporting.module').then((m) => m.ReportingModule),
        data: { roles: REPORTING.roles, features: REPORTING.requiredFeatures },
        title: REPORTING.name,
      },
    ],
  },

  // authentication routes
  {
    path: 'auth',
    resolve: {
      publicCompanyDetails: PublicCompanyDetailsResolverService,
    },
    loadChildren: () => AuthModule,
  },

  // fallback "page not found" route
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title, private store: Store) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const company = this.store.selectSnapshot(CompanyState.company);
    const companyLogoType = company?.companyLogoType;

    const titleTail =
      companyLogoType === CompanyLogoType.BEYOND_WORK_LIFE
        ? '360 Beyond Work Life'
        : companyLogoType === CompanyLogoType.REWARDS
        ? '360 Employee Rewards'
        : '360° Suite';

    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`${title} | ${titleTail}`);
    }
  }
}

@Injectable()
export class PathPreserveQueryLocationStrategy extends PathLocationStrategy {
  readonly propertiesToPreserve = ['startDate', 'endDate', 'granularity'];

  constructor(
    private platformLocation: PlatformLocation,
    private urlSerializer: UrlSerializer,
    @Optional() @Inject(APP_BASE_HREF) _baseHref?: string
  ) {
    super(platformLocation, _baseHref);
  }

  override prepareExternalUrl(internal: string): string {
    const path = super.prepareExternalUrl(internal);

    const existingURLSearchParams = new URLSearchParams(
      this.platformLocation?.search ?? ''
    );
    const queryParamsToKeep: Record<string, string> = {};
    for (const key of existingURLSearchParams.keys()) {
      if (this.propertiesToPreserve.includes(key)) {
        queryParamsToKeep[key] = existingURLSearchParams.get(key)!;
      }
    }

    const urlTree = this.urlSerializer.parse(path);
    const nextQueryParams = urlTree.queryParams;
    urlTree.queryParams = { ...queryParamsToKeep, ...nextQueryParams };
    return urlTree.toString();
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
    { provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy },
  ],
})
export class AppRoutingModule {}
