import { Component, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { combineLatest, filter, lastValueFrom, map } from 'rxjs';
import {
  AssumableEmployee,
  AssumeEmployee,
  AuthState,
  LogOut,
} from '../../../auth/auth.state';
import { ProfilePageComponent } from '../../../auth/components/profile-page/profile-page.component';
import { UserRole } from '../../../auth/interfaces/user-role.enum';
import {
  APP_ROUTES,
  MANAGE_MY_INFORMATION_ROUTES,
  REPORTING_ROUTES,
} from '../../../routes';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogOptions,
  DialogConfirmationButtonRole,
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { CompanyState } from '../../state/company.state';

@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  template: `<nav
    class="relative z-10 self-start w-full h-screen overflow-x-visible border-b select-none sm:w-16 print:hidden shrink-0 xl:w-64 sm:max-h-full max-h-12 sm:sticky sm:top-0 border-b-gray-700 sm:border-b-0"
    [class.nav-open]="isOpen"
    [class.max-h-screen]="isOpen"
    aria-label="Navigation"
  >
    <!-- nav container to allow overflow hover -->
    <div
      class="relative shrink-0 flex flex-col w-full h-full px-2 overflow-x-hidden overflow-y-hidden bg-gray-950 sm:hover:w-64 xl:duration-[0] xl:transition-none transition-[width] duration-300 group"
      [class.bg-red-950]="isImpersonating$ | async"
    >
      <!-- logo bar-->
      <div
        class="relative flex items-center justify-center h-12 py-2 sm:h-20 shrink-0"
      >
        <!-- menu button -->
        <button
          class="absolute flex items-center space-x-2 right-2 sm:hidden"
          (click)="toggleOpen()"
        >
          <ng-container *ngIf="!isOpen; else closeButton">
            <mat-icon>menu</mat-icon>
            <span>Menu</span>
          </ng-container>
          <ng-template #closeButton>
            <mat-icon>close</mat-icon>
            <span>Close</span>
          </ng-template>
        </button>

        <!-- small logo -->
        <img
          src="assets/images/logo-360-dark.svg"
          class="absolute block h-6 transition-opacity duration-300 opacity-0 sm:opacity-100 sm:group-hover:opacity-0 xl:hidden"
        />
        <!-- big logo -->
        <div
          class="absolute w-40 h-10 transition-opacity duration-300 sm:h-16 left-2 sm:left-[unset] sm:opacity-0 xl:opacity-100 sm:group-hover:opacity-100"
        >
          <app-company-logo
            *ngIf="company$ | async as company"
            [companyId]="company.id"
            [isBeyondWorkLife]="company.companyLogoType === 'BEYOND_WORK_LIFE'"
            [dark]="true"
          ></app-company-logo>
        </div>
      </div>

      <!-- links (scrollable container) -->
      <div class="flex-grow h-full pt-4 pb-8 overflow-y-auto sm:pt-0">
        <ul class="space-y-2">
          <app-sidebar-item-predefined
            *ngIf="routes.WELCOME.requiredFeatures | hasFeature | async"
            [route]="routes.WELCOME"
          ></app-sidebar-item-predefined>

          <app-sidebar-item-predefined
            *ngIf="routes.OVERVIEW.requiredFeatures | hasFeature | async"
            [route]="routes.OVERVIEW"
          ></app-sidebar-item-predefined>

          <app-sidebar-submenu
            *ngIf="
              (routes.MANAGE_MY_INFORMATION.requiredFeatures
                | hasFeature
                | async) &&
              (routes.MANAGE_MY_INFORMATION.roles | hasPermission | async)
            "
          >
            <ng-container slot="dropdown-header">
              <app-sidebar-item-predefined
                *ngIf="
                  routes.MANAGE_MY_INFORMATION.requiredFeatures
                    | hasFeature
                    | async
                "
                [route]="routes.MANAGE_MY_INFORMATION"
              ></app-sidebar-item-predefined>
            </ng-container>
            <ng-container slot="dropdown-items">
              <ng-container *ngFor="let route of MANAGE_MY_INFORMATION_ROUTES">
                <app-sidebar-item-predefined
                  *ngIf="route.requiredFeatures | hasFeature | async"
                  [route]="route"
                ></app-sidebar-item-predefined>
              </ng-container>
            </ng-container>
          </app-sidebar-submenu>

          <app-sidebar-item-predefined
            *ngIf="routes.COMPENSATION.requiredFeatures | hasFeature | async"
            [route]="routes.COMPENSATION"
          ></app-sidebar-item-predefined>

          <app-sidebar-item-predefined
            *ngIf="routes.BENEFITS.requiredFeatures | hasFeature | async"
            [route]="routes.BENEFITS"
          ></app-sidebar-item-predefined>

          <app-sidebar-item-predefined
            *ngIf="
              routes.WORK_LIFE_BENEFITS.requiredFeatures | hasFeature | async
            "
            [route]="routes.WORK_LIFE_BENEFITS"
          ></app-sidebar-item-predefined>

          <app-sidebar-item-predefined
            *ngIf="routes.PAID_TIME_OFF.requiredFeatures | hasFeature | async"
            [route]="routes.PAID_TIME_OFF"
          ></app-sidebar-item-predefined>

          <app-sidebar-item-predefined
            *ngIf="routes.STATEMENT.requiredFeatures | hasFeature | async"
            [route]="routes.STATEMENT"
          ></app-sidebar-item-predefined>

          <app-sidebar-item-predefined
            *ngIf="routes.PAYSLIPS.requiredFeatures | hasFeature | async"
            [route]="routes.PAYSLIPS"
          ></app-sidebar-item-predefined>

          <app-sidebar-item-predefined
            *ngIf="routes.TAX_FORMS.requiredFeatures | hasFeature | async"
            [route]="routes.TAX_FORMS"
          ></app-sidebar-item-predefined>

          <app-sidebar-item-predefined
            *ngIf="routes.NEWS.requiredFeatures | hasFeature | async"
            [route]="routes.NEWS"
          ></app-sidebar-item-predefined>

          <app-sidebar-item-predefined
            *ngIf="
              routes.CONTACT_INFORMATION.requiredFeatures | hasFeature | async
            "
            [route]="routes.CONTACT_INFORMATION"
          ></app-sidebar-item-predefined>

          <!-- admin -->
          <app-sidebar-separator
            *ngIf="[ADMIN] | hasPermission | async"
          ></app-sidebar-separator>

          <app-sidebar-item-predefined
            [route]="routes.USER_MANAGEMENT"
          ></app-sidebar-item-predefined>

          <app-sidebar-item-predefined
            *ngIf="routes.CHANGE_APPROVAL.requiredFeatures | hasFeature | async"
            [route]="routes.CHANGE_APPROVAL"
          ></app-sidebar-item-predefined>

          <app-sidebar-submenu
            *ngIf="
              (routes.REPORTING.requiredFeatures | hasFeature | async) &&
              (routes.REPORTING.roles | hasPermission | async)
            "
          >
            <ng-container slot="dropdown-header">
              <app-sidebar-item-predefined
                *ngIf="routes.REPORTING.requiredFeatures | hasFeature | async"
                [route]="routes.REPORTING"
              ></app-sidebar-item-predefined>
            </ng-container>

            <ng-container slot="dropdown-items">
              <ng-container *ngFor="let route of REPORTING_ROUTES">
                <app-sidebar-item-predefined
                  *ngIf="route.requiredFeatures | hasFeature | async"
                  [route]="route"
                ></app-sidebar-item-predefined>
              </ng-container>
            </ng-container>
          </app-sidebar-submenu>

          <app-sidebar-dropdown
            icon="settings"
            heading="Settings"
            *ngIf="[ADMIN] | hasPermission | async"
          >
            <ng-container slot="dropdown-items">
              <app-sidebar-item-predefined
                [route]="routes.COMPANY_SETTINGS"
              ></app-sidebar-item-predefined>

              <app-sidebar-item-predefined
                *ngIf="
                  routes.WELCOME_SETTINGS.requiredFeatures | hasFeature | async
                "
                [route]="routes.WELCOME_SETTINGS"
              ></app-sidebar-item-predefined>

              <app-sidebar-item-predefined
                *ngIf="
                  routes.MANAGE_MY_INFORMATION_SETTINGS.requiredFeatures
                    | hasFeature
                    | async
                "
                [route]="routes.MANAGE_MY_INFORMATION_SETTINGS"
              ></app-sidebar-item-predefined>

              <app-sidebar-item-predefined
                *ngIf="
                  routes.OVERVIEW_SETTINGS.requiredFeatures | hasFeature | async
                "
                [route]="routes.OVERVIEW_SETTINGS"
              ></app-sidebar-item-predefined>

              <app-sidebar-item-predefined
                *ngIf="
                  routes.COMPENSATION_SETTINGS.requiredFeatures
                    | hasFeature
                    | async
                "
                [route]="routes.COMPENSATION_SETTINGS"
              ></app-sidebar-item-predefined>

              <app-sidebar-item-predefined
                *ngIf="
                  routes.BENEFITS_SETTINGS.requiredFeatures | hasFeature | async
                "
                [route]="routes.BENEFITS_SETTINGS"
              ></app-sidebar-item-predefined>

              <app-sidebar-item-predefined
                *ngIf="
                  routes.WORK_LIFE_BENEFITS_SETTINGS.requiredFeatures
                    | hasFeature
                    | async
                "
                [route]="routes.WORK_LIFE_BENEFITS_SETTINGS"
              ></app-sidebar-item-predefined>

              <app-sidebar-item-predefined
                *ngIf="
                  routes.PAID_TIME_OFF_SETTINGS.requiredFeatures
                    | hasFeature
                    | async
                "
                [route]="routes.PAID_TIME_OFF_SETTINGS"
              ></app-sidebar-item-predefined>

              <app-sidebar-item-predefined
                *ngIf="
                  routes.STATEMENT_SETTINGS.requiredFeatures
                    | hasFeature
                    | async
                "
                [route]="routes.STATEMENT_SETTINGS"
              ></app-sidebar-item-predefined>

              <app-sidebar-item-predefined
                *ngIf="
                  routes.NEWS_SETTINGS.requiredFeatures | hasFeature | async
                "
                [route]="routes.NEWS_SETTINGS"
              ></app-sidebar-item-predefined>

              <app-sidebar-item-predefined
                *ngIf="
                  routes.CONTACT_INFORMATION_SETTINGS.requiredFeatures
                    | hasFeature
                    | async
                "
                [route]="routes.CONTACT_INFORMATION_SETTINGS"
              ></app-sidebar-item-predefined>
            </ng-container>
          </app-sidebar-dropdown>

          <app-sidebar-item-predefined
            [route]="routes.PROCESSING_LOGS"
          ></app-sidebar-item-predefined>

          <app-sidebar-item-predefined
            [route]="routes.USER_LOGS"
          ></app-sidebar-item-predefined>
        </ul>
      </div>

      <!-- footer -->
      <div
        class="flex flex-col w-full text-sm transition-opacity duration-300 border-t sm:w-60 sm:opacity-0 shrink-0 group-hover:opacity-100 xl:opacity-100 border-t-gray-700"
      >
        <!-- account popup -->
        <ng-template #accountPopup let-hide>
          <div class="w-64">
            <!-- account switching -->
            <div
              *ngIf="
                (assumableAccounts$ | async)?.length &&
                !(isImpersonating$ | async)
              "
              class="py-4 border-b border-b-gray-700"
            >
              <div
                class="mx-2 mb-2 text-xs font-bold text-gray-100 uppercase border-b border-b-gray-700"
              >
                Change account
              </div>
              <div class="overflow-y-auto max-h-96">
                <button
                  *ngFor="let employee of assumableAccounts$ | async"
                  matRipple
                  rounded
                  (click)="hide() || assumeEmployee(employee)"
                  class="flex flex-row items-center w-full px-2 py-2 space-x-4 text-left text-gray-100 rounded hover:bg-gray-700 hover:underline"
                >
                  <div
                    class="flex items-center justify-center w-10 h-10 rounded-full"
                  >
                    <app-company-logo
                      [dark]="true"
                      [companyId]="employee.company.id"
                      [isBeyondWorkLife]="
                        employee.company.companyLogoType === 'BEYOND_WORK_LIFE'
                      "
                    ></app-company-logo>
                  </div>
                  <div class="flex flex-col">
                    <b>{{ employee.company.name }}</b>
                    <span
                      >{{ employee.firstName }} {{ employee.lastName }} ·
                      {{ 'ROLES.' + employee.role | translate }}
                    </span>
                  </div>
                </button>
              </div>
            </div>

            <!-- actions -->
            <div class="flex justify-between px-2">
              <!-- sign out -->
              <button
                mat-button
                (click)="hide() || logOut()"
                aria-label="Sign out"
                color="secondary"
              >
                <mat-icon>logout</mat-icon>
                <span class="ml-2">Sign out</span>
              </button>

              <!-- account settings -->
              <button
                mat-icon-button
                class="shrink-0"
                (click)="hide() || openAccountSettings()"
                aria-label="Account settings"
                tippy="Account settings"
                *ngIf="!(isImpersonating$ | async)"
              >
                <mat-icon>settings</mat-icon>
              </button>
            </div>
          </div>
        </ng-template>
        <!-- account and sign out -->
        <div
          *ngIf="currentAccount$ | async as currentAccount"
          matRipple
          class="flex flex-row items-center py-4 pl-4 space-x-2 border-b rounded cursor-pointer border-b-gray-700 hover:bg-gray-700 group/account"
          [tippy]="accountPopup"
          [interactive]="true"
          variation="darkPopper"
          theme="dark"
        >
          <!-- account icon -->
          <div
            class="w-10 h-10 bg-gray-700 group-hover/account:bg-gray-600 rounded-full text-center leading-[40px]"
          >
            <mat-icon
              class="text-gray-400"
              inline="true"
              style="font-size: 24px"
            >
              people_alt
            </mat-icon>
          </div>

          <!-- account details -->
          <div class="flex flex-col">
            <div class="font-bold text-gray-100">
              {{ currentAccount.firstName }} {{ currentAccount.lastName }}
            </div>
            <div class="flex flex-row space-x-1 text-gray-600 flex-nowrap">
              <!-- role -->
              <span class="text-gray-200"
                >#{{ currentAccount.employeeNumber }}</span
              >
            </div>
          </div>
        </div>

        <!-- copyright -->
        <app-powered-by-epi-use
          class="my-4"
          [dark]="true"
        ></app-powered-by-epi-use>
      </div>
    </div>
  </nav>`,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class SidebarComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly matDialog = inject(MatDialog);
  private readonly toaster = inject(HotToastService);

  isOpen = false;
  keepExpanded = false;

  routes = APP_ROUTES;
  REPORTING_ROUTES = REPORTING_ROUTES;
  MANAGE_MY_INFORMATION_ROUTES = MANAGE_MY_INFORMATION_ROUTES;

  ADMIN = UserRole.ADMIN;

  company$ = this.store.select(CompanyState.company);

  employeeRole$ = this.store.select(AuthState.role);
  employeeName$ = this.store.select(AuthState.fullName);
  employeeNumber$ = this.store.select(AuthState.employeeNumber);
  employeeEmail$ = this.store.select(AuthState.userEmail);
  accessToken$ = this.store.select(AuthState.accessToken);
  isImpersonating$ = this.store.select(AuthState.isImpersonating);

  year = new Date().getFullYear();

  availableAccounts$ = this.store.select(AuthState.associatedEmployees);
  currentAccount$ = combineLatest([
    this.availableAccounts$,
    this.accessToken$,
  ]).pipe(
    map(
      ([accounts, accessToken]) =>
        accessToken &&
        accounts.find(
          (account) =>
            account.companyId === accessToken.companyId &&
            account.id === accessToken.employeeId
        )
    )
  );
  assumableAccounts$ = combineLatest([
    this.availableAccounts$,
    this.currentAccount$,
  ]).pipe(
    map(([accounts, currentAccount]) =>
      accounts.filter(
        (account) => !currentAccount || account.id !== currentAccount.id
      )
    )
  );

  ngOnInit(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.isOpen = false;
      });
  }

  logOut() {
    const confirmationOptions: ConfirmationDialogOptions = {
      title: 'Sign out?',
      body: 'Are you sure you want to sign out?',
      buttons: [
        {
          text: 'Cancel',
          secondary: true,
          role: DialogConfirmationButtonRole.Cancel,
        },
        {
          text: 'Sign out',
          primary: true,
          role: DialogConfirmationButtonRole.Accept,
        },
      ],
    };
    const confirmationDialog = this.matDialog.open(
      ConfirmationDialogComponent,
      {
        data: confirmationOptions,
      }
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result !== DialogConfirmationButtonRole.Accept) return;
      this.store.dispatch(new LogOut());
    });
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  async assumeEmployee(employee: AssumableEmployee) {
    const dialog = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Switch account?',
        body: `Are you sure you want to switch to account ${employee.firstName} ${employee.lastName} from ${employee.company.name}?`,
        buttons: [
          {
            role: DialogConfirmationButtonRole.Cancel,
            text: 'Cancel',
            secondary: true,
          },
          {
            role: DialogConfirmationButtonRole.Accept,
            text: 'Switch account',
            primary: true,
          },
        ],
      } as ConfirmationDialogOptions,
    });

    const res = await lastValueFrom(dialog.afterClosed());
    if (res !== DialogConfirmationButtonRole.Accept) {
      return;
    }

    this.store
      .dispatch(
        new AssumeEmployee({
          employeeId: employee.id,
          companyId: employee.companyId,
        })
      )
      .pipe(
        untilDestroyed(this),
        this.toaster.observe({
          loading: 'Switching accounts...',
          success: 'Account switched',
          error:
            'An error occurred: unable to switch accounts. Please try again.',
        })
      )
      .subscribe(() => {});
  }

  openAccountSettings() {
    this.matDialog.open(ProfilePageComponent, {
      width: '1280px',
      height: '90vh',
      panelClass: 'no-padding-dialog',
    });
  }
}
